import React, { useState, useEffect } from 'react'
import BlockContent from "@sanity/block-content-to-react"
import { Link } from 'gatsby'

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"

import Scrollbar from 'react-scrollbars-custom'

import {
  LinkArrow
} from 'src/components/svgs'

const SlideshowCard = ({spice, active, setActive, index}) => {
  return (
    <div className='col pr ws--normal product__card-col bl bc c6 c4--800'>
      <div className='p2 mb1 spice__scroll-image'>
        {spice.image ? (
          <>
            {spice.podImage ? (
              <Image className='pb2' imageId={spice.podImage.imageId} alt={spice.imageAlt} width={600} />
            ) : (
              <Image className='pb2' imageId={spice.image} alt={spice.imageAlt} />
            )}
          </>
        ) : (
          <span className='empty'>Missing Image</span>
        )}
        <h3 className='founders mb1 s24'>{spice.productTitle ? spice.productTitle : spice.title}</h3>
        {spice.stats && spice.stats.map(stat => (
          <div className='block' key={stat._key}>
            <span className='s14'>{stat.identifier}: {stat.stat}</span>
          </div>
        ))}
        {spice.recipeUrl && (
          <div className='mt1 mb1'>
            <span className='s14 db founders'>Recipe</span><br />
            <a className='link-underline founders bold' href={spice.recipeUrl} target='_blank' rel='noopener'>
              {spice.recipeCta ? spice.recipeCta : 'Open Recipe'} <LinkArrow />
            </a>
          </div>
        )}
        {spice.recipeNote && (
          <div className='pt3'>
            <button onClick={() => {
              if (active === index) {
                setActive(null)
              } else {
                setActive(index)
              }
            }} className='pa bottom x bt bc spice__recipe-note left'>
              <span className=' block courier s14'>Recipe Note +</span>
            </button>
            {active === index && (
              <div onClick={() => setActive(null)} className='pa left bc bottom x p2 bt bg--white'>
                <BlockContent blocks={spice.recipeNote} serializers={Serializer} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const SpiceSlideshow = ({ title, spices, subTitle }: {
  title: string
  spices: any[]
  subTitle?: string
}) => {
  const [active, setActive] = useState(null)
  return (
    <div className='mt4 mb4 mt8--800 of--hidden'>
      <div className='tc'>
        <h2>{title}</h2>
        {subTitle && (<h5 className='caps founders s16 mt1'>{subTitle}</h5>)}
      </div>
      <div className='bt bb bc mt3 mt4--600 hide--800'>
        <div className='ws--nowrap  js-filter-hide x  c14 df product__rows ofy--scroll'>
          {spices && spices.map((spice, i) => <SlideshowCard key={i} setActive={setActive} index={i} active={active} spice={spice} />)}
        </div>
      </div>
      <div className='bt bc mt3 mt4--600 show--800'>
        <Scrollbar style={{ width: '100%', height: 665 }} noScrollY={true}>
          <div className='ws--nowrap bc df row product__rows'>
            {spices && spices.map((spice, i) => <SlideshowCard key={i} setActive={setActive} index={i} active={active} spice={spice} />)}
          </div>
        </Scrollbar>
      </div>
    </div>
  )
}