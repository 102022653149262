import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderProductModules } from 'src/utils/renderProductModules'

import { ProductHero } from 'src/components/product/hero'
import { ProductSchema } from 'src/components/product/schema'

import { viewProduct } from 'src/components/analytics'

import { useSetPage } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'

export interface ProductProps {
  pageContext: {
    modules: []
    title: string
    slug: string
    meta: {}
    content: {
      shopify: {}
      main: {}
    }
  }
  preview?: boolean
}

const Product = ({
  pageContext,
  preview = false
}: ProductProps) => {
  const setPage = useSetPage()
  const {
    title,
    modules,
    chef,
    variants,
    linkedProducts,
    slug,
    heroImages,
    defaultMeta,
    meta,
    content
  } = pageContext

  useEffect(() => {
    setPage(slug)
    // Fire Analytics
    viewProduct(
      {
        sku: variants[0].sku,
        title: title,
        variantTitle: variants[0].variantTitle,
        price: content.shopify.defaultPrice
      }
    )
  }, [])

  const url = `products/${slug}`

  return (
    <div className='ac x'>
      {preview && (
        <div className='bg--red bb pt5 pl5 pr5 pb2 tc color--white'>
          <h4 className='mt8'>This is a Preview</h4>
        </div>
      )}
      <div dangerouslySetInnerHTML={{
          __html: `
            <script type="application/ld+json">
              ${JSON.stringify(ProductSchema(content.main, content.shopify))}
            </script>
          `
        }} />
      <SEO metaInfo={meta} defaultMeta={defaultMeta} defaultTitle={`${title} | Occo Spices`} pagePath={url} />
      <div className={cx('mxa x al site__main', slug)}>
        <ProductHero main={content.main} linkedProducts={linkedProducts} variants={variants} images={heroImages} chef={chef} product={content.shopify} />
        {RenderProductModules(modules)}
      </div>
    </div>
  )
}

export default Product