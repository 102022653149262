import React from "react"

import BlockContent from "@sanity/block-content-to-react"

import { Image } from 'src/components/image'

import { Serializer } from "src/utils/serializer"

import { Tab } from 'src/components/svgs'
import { RotatingText } from 'src/utils/rotatingText'

export interface SpiceStatsProps {
  data: {
    spice: {
      fact: any[]
      stats: any[]
      image: string
      imageAlt?: string
      secondaryImage: string
      secondaryImageAlt?: string
    }
    badgeText?: string
  }
}

export const SpiceStats = ({ data }: SpiceStatsProps) => {
  const {
    spice: {
      fact,
      stats,
      image,
      imageAlt,
      secondaryImage,
      secondaryImageAlt
    },
    badgeText
  } = data

  return (
    <div className='p2'>
      <div className="mxa container--xl ba bc mt4 mb4 mt8--800 mb8--800 pice__stats">
        <div className='f fw jcb aist'>
          <div className='grid--50-800 x f jcb fdc'>
            <div className='f jcc aic y'>
              <div className='p2 pt3 p4--800 tc'>
                <span className='h2 tc db mb1'>Fun Fact</span>
                <BlockContent blocks={fact} serializers={Serializer} />
              </div>
            </div>
            <div className='p4--800 show--800 bc bt'>
                <Image imageId={image} alt={imageAlt} className='x ' />
            </div>
          </div>
          <div className='rel grid--50-800 x bc bl--800'>
            <div className='pr x p4--800'>
              <div className='pr x'>
                <div className='pa right color--blue z1 top'>
                  <Tab />
                  <div className='pa top right badge__text founders color--white caps'>
                    <RotatingText text={badgeText ? badgeText : 'super-fresh'} />
                  </div>
                </div>
                <Image imageId={secondaryImage} alt={secondaryImageAlt} className='x' />
              </div>
            </div>
            <div className='mt1 pb3 p2 bc bt--800'>
              
              <div className=' p2--800 x'>
                <div className='tc ba bc p2'>
                  <h2>Spice Stats</h2>
                </div>
                <div className='p2 spice__stats-facts show--600 ss14 courier bc ba'>
                  {stats && stats.map(stat => (
                    <div key={stat._key} className='f jcb aie ws--nowrap x'>
                      <span className='db courier mr1'>{stat.identifier}:</span>
                      <div className='spice__stats-border color--theme pr1 pl1' />
                      <span className='db courier ml1'>{stat.stat}</span>
                    </div>
                  ))}
                </div>
                <div className='spice__stats-small ss14 courier bc ba p2'>
                {stats && stats.map(stat => (
                    <div key={stat._key} className='mb2 hide--600 aie x'>
                      <span className='db m0 p0 courier bold'>{stat.identifier}:</span>
                      <span className='db  m0 p0 courier '>{stat.stat}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}