import React from "react"

import BlockContent from "@sanity/block-content-to-react"
import { Serializer } from "src/utils/serializer"

import {
  LinkArrow
} from 'src/components/svgs'

export interface ExternalRecipesProps {
  data: {
    text?: any[]
    recipes: any[]
  }
}

export const ExternalRecipes = ({ data }: ExternalRecipesProps) => {
  const { text, recipes } = data
  return (
    <div className="container--1200 outer mxa mt2 mt6--800 al p2 p x">
      <div className='row df fw jcb mb4--800 mb6--1000'>
        <div className='col c7--1000 c6--1400 pb2'>
          <div className='pr3--800 x'>
            <BlockContent blocks={text} serializers={Serializer} />
          </div>
        </div>
        <div className='col c7--1000 c6--1400'>
          <div className='bt bt--hide-800'>
            {recipes.map(recipe => (
              <div key={recipe._key} className='mb1 mt1 pb1 mb2--600 pb3--800 bb'>
                <div className='df jcb row fw ais'>
                  <div className='col c14 c10--600'>
                    <h3 className='s30 external__recipe-title sans'>{recipe.title}</h3>
                    <p className='courier ss14'>Uses: {recipe.uses}</p>
                  </div>
                  {recipe.recipeUrl && (
                    <div className='col c14 c3--600 tr--600 mb1'>
                      <a href={recipe.recipeUrl} target='_blank' rel='noopener nofollow' className='link-underline ws--nowrap mt1 caps founders s16'>Go to Recipe <LinkArrow /></a>
                    </div>
                  )}
                </div>
                {recipe.description && (
                  <div className='mt1'>
                    <span className='italic'>{recipe.description}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}