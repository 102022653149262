import React from "react"
import cx from 'classnames'

import { Image } from 'src/components/image'

import {
  CheckMark,
  BigX,
  Face
} from 'src/components/svgs'

export interface CompareChartProps {
  data: {
    compare: {
      title?: string
      compareHeaders: any[]
      compareChartRows: any[]
      subtitle: string
      image: string
    }
  }
}

export const CompareChart = ({ data }: CompareChartProps) => {
  const {
    subtitle,
    compareHeaders,
    compareChartRows,
    title
   } = data.compare

  return (
    <div className=''>
      <div className="container--xl  mt6 mb2 mb7--800 outer mxa x">
        <div className='tc pt3 mb3'>
          <h2 className='h2'>{title}</h2>
          <h5 className='caps ss14 mt2 di founders medium'>{subtitle}</h5>
        </div>
        <div className='ofy--scroll'>
          <div className='p2 pl3--800 pr3--800 compare__chart'>
            <div className='ba bc x'>
              <div className='df jcb row gutter--none df compare__row'>
                <div className='compare__col col c4 tc p1'>
                  <div className='df y jcc aic'>
                    <Face className='spin' />
                  </div>
                </div>
                {compareHeaders && compareHeaders.map((heading, i) => (
                  <div className={cx('compare__col col c4 jcc bc df aic tc bl p2', {

                  })} key={i}>
                    <div className='p1 df jcc aic y'>
                      <span className='caps founders s14 s16--800 medium'>{heading}</span>
                    </div>
                  </div>
                ))}
              </div>
              {compareChartRows.map((item, i) => (
                <div className='compare__row row gutter--none bt bc df jcb' key={i}>
                  <div className='compare__col col c4 tl founders'>
                    <div className='p2'>
                      <span className='s14 s16--800 founders caps medium'>{item.title}</span>
                    </div>
                  </div>
                  {item.checks.map((check, ci) => (
                    <div key={ci} className='compare__col compare__svgs color--theme col c4 bc bl tc p2 p4--800'>
                      {check ? (
                        <CheckMark />
                      ) : (
                        <BigX />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}