import React from "react"

export interface ActivityModuleProps {
  data: {
    title?: string
    shortDescription?: string
  }
}

export const ActivityModule = ({ data }: ActivityModuleProps) => {
  const { title, steps } = data
  return (
    <div className='pl1 pr1 none'>
      <div className="container--xl p2--800 mt5--800 outer mxa py2 al p1 p x">
        <div className='p2 ba bc'>
          <div className='row df fw jcb aic'>
            <div className='col c14 c4--800 mt1 tc'>
              <span className='courier'>Activity:</span>
              <h2 className='m0 p0'>{title}</h2>
            </div>
            <div className='col c14 c4--800 tc'>
              <div className='activity__video mt3 mb3 mt5--800 mb5--800 x ba bc of--hidden'>
                Video
              </div>
            </div>
            <div className='col c4--800 c14'>
              <div>
                {steps && steps.map((step, i) => {
                  return (
                    <div key={i} className='f pr3--800 founders jcs ais mb2 mt2'>
                      {steps.length > 1 && (<span className='mr1 pr activity__number s14'>({i + 1})</span>)}
                      <div className='s22'>{step}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}