import React from 'react'
import loadable from '@loadable/component'

import { StandardText, StandardTextProps } from 'src/components/global/standardText'
import { TwoColumnModule, TwoColumnModuleProps } from 'src/components/global/twoColumnModule'
import { IncludedRecipes, IncludedRecipesProps } from 'src/components/product/includedRecipes'
import { IncludedSpices, IncludedSpicesProps } from 'src/components/product/includedSpices'
import { ChefModule, ChefModuleProps } from 'src/components/product/chefModule'
import { SpiceStats, SpiceStatsProps } from 'src/components/product/spiceStats'
import { ActivityModule, ActivityModuleProps } from 'src/components/product/activityModule'
import { WhatToExpect, WhatToExpectProps } from 'src/components/product/whatToExpect'
import { ExternalRecipes, ExternalRecipesProps } from 'src/components/product/externalRecipes'
import { ImageModule, ImageModuleProps } from 'src/components/global/imageModule'
import { TextHero, TextHeroProps } from 'src/components/global/textHero'
import { TextBlock, TextBlockProps } from 'src/components/global/textBlock'
import { CompareChart, CompareChartProps } from 'src/components/global/compareChart'

const Deck = loadable(() => import('src/components/global/deck'))


export const ProductModules = ({ reactModule, type }: { type: string, reactModule: any }) => {
  switch(type) {
    case 'standardText':
      return <StandardText data={reactModule as StandardTextProps["data"]} />
    case 'twoColumnModule':
      return <TwoColumnModule data={reactModule as TwoColumnModuleProps["data"]} />
    case 'includedRecipes':
      return <IncludedRecipes data={reactModule as IncludedRecipesProps["data"]} />
    case 'includedSpices':
      return <IncludedSpices data={reactModule as IncludedSpicesProps["data"]} />
    case 'chefModule':
      return <ChefModule data={reactModule as ChefModuleProps["data"]} />
    case 'textHero':
      return <TextHero data={reactModule as TextHeroProps["data"]} />
    case 'expectReference':
      return <WhatToExpect data={reactModule as WhatToExpectProps["data"]} />
    case 'imageModule':
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />
    case 'textBlock':
      return <TextBlock data={reactModule as TextBlockProps["data"]} />
    case 'spiceStats':
      return <SpiceStats data={reactModule as SpiceStatsProps["data"]} />
    case 'externalRecipes':
      return <ExternalRecipes data={reactModule as ExternalRecipesProps["data"]} />
    case 'activityModule':
      return <ActivityModule data={reactModule as ActivityModuleProps["data"]} />
    case 'compareChart':
      return <CompareChart data={reactModule as CompareChartProps["data"]} />
    case 'reference':
      if (reactModule.deck) {
        return <Deck data={reactModule.deck} />
      }
      return null
    default:
      return (<span>{type}</span>)
  }
}