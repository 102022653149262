import React from "react"
import cx from 'classnames'

import { RecipeCard } from 'src/components/product/recipeCard'
import { ProductInt } from 'src/interfaces/product'

export interface IncludedRecipesProps {
  data: {
    title?: string
    shortDescription?: string
    recipes?: [ProductInt]
  }
}

export const IncludedRecipes = ({ data }: IncludedRecipesProps) => {
  const { title, recipes } = data
  return (
    <div className='p1'>
      <div className="container--xl outer mxa py2 al p2--800 p x pt2 pb2 pt5--800 pb5--800">
        <div className='tc mb2 mb4--800'>
          <h2 className='m0 p0'>{title}</h2>
        </div>
        <div className={cx('row df fw', {
          'jcb': recipes.length > 3,
          'jcc': recipes.length <= 3
        })}>
          {recipes && recipes.map((singleProduct) => (
            <RecipeCard className='mb2 c7' key={singleProduct._id} {...singleProduct} />
          ))}
        </div>
      </div>
    </div>
  )
}