import React from "react"
import { RenderModules } from "src/utils/renderModules"

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'

import { SpiceClick } from 'src/components/product/spices/click'
import { SpiceSlideshow } from 'src/components/product/spices/slideshow'

export interface IncludedSpicesProps {
  data: {
    title?: string
    layoutStyle: string
    spices: any[]
  }
}

export const IncludedSpices = ({ data }: IncludedSpicesProps) => {
  const { title, layoutStyle, spices } = data
  const handleLayout = () => {
    switch (data.layoutStyle) {
      case 'slides':
        return <SpiceSlideshow {...data} />
      case 'click':
        return <SpiceClick {...data} />
      default: 
        return null
    }
  }
  return (
    <div className="pr of--hidden x">
      {handleLayout()}
    </div>
  )
}