import React, { useState, useEffect } from "react"
import cx from 'classnames'
import BlockContent from "@sanity/block-content-to-react"
import { useEmblaCarousel } from 'embla-carousel/react'

import { Image } from 'src/components/image'

import { Serializer } from "src/utils/serializer"
import { lineBreak } from 'src/utils/utils'

import {
  LargeArrow,
  LinkArrow,
} from 'src/components/svgs'

export interface ChefModuleProps {
  data: {
    chef: {
      faqs?: any[]
      bio: any[]
      name: string
      image: string
      chefSlideshow?: any[]
      content: any
      quickInformation: string
      qAndAImage?: string
    }
  }
}

export const ChefModule = ({ data }: ChefModuleProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, center: true })
  const [active, setActive] = useState(0)

  const { chef: {
    faqs,
    name,
    image,
    content,
    chefSlideshow,
    quickInformation,
    bio,
    qAndAImage
  } } = data

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('select', () => {
        setActive(emblaApi.selectedScrollSnap())
      })
    }
  }, [emblaApi])

  if (!content) {
    return null
  }

  return (
    <div className="bc al p1--600 x">
      <div className='container--xl mxa pr3--800 pl3--800'>
        <div className='bt chef__border-600 bc'>
          <div className='f fw jcb bb--800 bc aist'>
            <div className='tc pt3 pb2 p2 x grid--50-800'>
              <div className='container--500 mxa'>
                {quickInformation && (<span className='caps db mb2 mt1 s24--800 founders' dangerouslySetInnerHTML={{ __html: lineBreak(quickInformation) }} />)}
                <div className='pr4--800 pl4--800 chef__portrait'>
                  <Image imageId={image} alt={name} />
                </div>
                <div className='chef__name pr'>
                  <h3 className='italic mt2 x mb0 pb0'>Meet</h3>
                  <h2 className='s80 mt0 pt1--800 mb2'>{name}</h2>
                </div>
              </div>
            </div>
            <div className='x grid--50-800 p4--800 bl--800 bc'>
              {content.mainFoodImage && (<Image className='x y' imageId={content.mainFoodImage.image.asset._id} />)}
            </div>
          </div>
          <div className='p2'>
            <div className='container--800 mxa mt2 mb2 chef__description mt6--800 mb6--800 p2--800'>
              <BlockContent blocks={bio} serializers={Serializer} />
              {content.externalLinks && (
                <div className='mt2 mt5--800'>
                  {content.externalLinks?.map((link) => (
                    <a href={link.link} key={link._key} target='_blank' rel='noopener nofollow' className='caps s16 link-underline link-arrow founders mr3 di'>{link.title} <LinkArrow /></a>
                  ))}
                </div>
              )}
            </div>
          </div>
          {chefSlideshow && (
            <>
              <div className='of--hidden bt bc' ref={emblaRef}>
                <div className='df chef__slideshow'>
                  {chefSlideshow.map(({image}, i) => (
                    <div key={i} className='tc db chef__slide color--theme bc x pr p2 pt3 pb3 p4--800'>
                      <Image imageId={image.imageId} alt={image.altText} />
                    </div>
                  ))}
                </div>
              </div>
              <div className='chef__slideshow-controls mb8--800 mb4 bt bb bc '>
                <div className='mxa chef__slideshow-controls-inner bc bl br'>
                  <div className='x row df jcc aic'>
                    <div className='col tc c3 c2--600 p1'>
                      <button  onClick={() => emblaApi?.scrollPrev()}>
                        <LargeArrow className='rotate' />
                      </button>
                    </div>
                    <div className='col p1 tc courier c4 c2--600'>
                      <span className='s30'>
                        {active + 1} / {chefSlideshow.length}
                      </span>
                    </div>
                    <div className='col tc c3 c2--600 p1'>
                      <button onClick={() => emblaApi?.scrollNext()}>
                        <LargeArrow />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='f jcb fw aist bt pr bc'>
            <div className='grid--50-800 chef__faqs bc'>
              {faqs && faqs.map((faq, i) => {
                return (
                  <div key={faq._key} className=''>
                    <div className='row gutter--none bb bc chef__faqs-list df aist'>
                      <div className='col bc c2 color--theme'>
                        <div className='p1 p3--800 tc '>
                          <span className='courier s24 color--text'>Q</span>
                        </div>
                      </div>
                      <div className='col c12'>
                        <div className='p1 p3--800 bl bc'>
                          <span className='medium s24 founders'>{faq.question}</span>
                        </div>
                      </div>
                    </div>
                    <div className={cx('row gutter--none', {
                      'bb bc': faqs.length - 1 > i
                    })}>
                      <div className='col bc c2'>
                        <div className='p1 p3--800  tc'>
                          <span className='courier s24'>A</span>  
                        </div>
                      </div>
                      <div className='col c12'>
                        <div className='p1 p3--800 bl bc chef__faq-text'>
                          <BlockContent blocks={faq.text} serializers={Serializer} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='x p2 grid--50-800 bl--800 bc'>
              <div className='chef__faq-image psy top p2--800'>
                {content.qAndAImage && (<Image className='x' imageId={content.qAndAImage.image.asset._id} alt={content.qAndAImage.altText} />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}