import React, { useState } from 'react'
import BlockContent from "@sanity/block-content-to-react"
import cx from 'classnames'
import { Link } from 'gatsby'

import { Image } from 'src/components/image'
import { Serializer } from "src/utils/serializer"

import {
  LinkArrow,
  Arrow
} from 'src/components/svgs'

export const SpiceClick = ({ title, spices, subTitle }: { title: string, spices: any[], subTitle?: string}) => {
  const [active, setActive] = useState(0 as number)
  const [clickNav, setClickNav] = useState(false)
  return (
    <div className='p2'>
      <div className='x p2--800 container--xl mxa mt4 mb4 mb8--800'>
        <div className='ba bc'>
          <div className='z1 container--1300 mxa spice__click'>
            <div className='row f fw aist gutter--none'>
              <div className='col c4--1000 p3--800 pb0--800 bc bb spice__click-nav'>
                <div className='f jcb fdc y pl2--800 pr2--800 pl1 pr1'>
                  <div>
                    <h2 className='p1 mt0 pt0 pt2 pt0--800 ba--hide-800'>{title}</h2>
                    <div className='p1 ba--hide-1000 show--1000 spice__selector mt2 mb2'>
                      {spices && spices.map((spice, i) => {
                        return (
                          <div key={i} className=''>
                            <button className={cx('button--none tl pt1 pb1 button--spice spice__click-button bn p0 m0 courier s14', {
                              'active': active === i
                            })} onClick={() => setActive(i)}>
                              {spice.productTitle ? spice.productTitle : spice.title}
                              <Arrow className={cx('ml1 spice__click-arrow show--1200', {
                                'active': i === active
                              })} />
                            </button>
                          </div>
                        )
                      })}
                    </div>
                    <div className='hide--1000 pl1 pr1 x'>
                      <button onClick={() => setClickNav(!clickNav)} className={cx('button--none df pt1 pb1 jcb aic spice__button-mobile button--spice bn p0 m0 courier x s14', {
                        'open': clickNav
                      })}>
                        {spices[active].title}
                        <svg className='' width="8" height="16" viewBox="0 0 8 16" fill="none">
                          <path d="M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1L4.5 1ZM3.64645 15.3536C3.84171 15.5488 4.15829 15.5488 4.35355 15.3536L7.53553 12.1716C7.7308 11.9763 7.7308 11.6597 7.53553 11.4645C7.34027 11.2692 7.02369 11.2692 6.82843 11.4645L4 14.2929L1.17157 11.4645C0.97631 11.2692 0.659728 11.2692 0.464465 11.4645C0.269203 11.6597 0.269203 11.9763 0.464465 12.1716L3.64645 15.3536ZM3.5 1L3.5 15L4.5 15L4.5 1L3.5 1Z" fill="currentColor"/>
                        </svg>
                      </button>
                      {clickNav && (
                        <div className='mt1'>
                          {spices && spices.map((spice, i) => {
                            return (
                              <div key={i} className=''>
                                <button className={cx('button--none pt1 pb1 button--spice spice__click-button bn p0 m0 courier s14', {
                                  'none': active === i
                                })} onClick={() => {
                                  setActive(i)
                                  setClickNav(!clickNav)
                                }}>
                                  {spice.title}
                                </button>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='p1 pb2'>
                    {subTitle && (
                      <div>
                        <svg className='show--800' width="46" height="58" viewBox="0 0 46 58" fill="none">
                          <path d="M22.9124 58C28.1695 58 32.5943 54.4182 33.7771 49.5417C33.7771 49.4554 33.821 49.3691 33.821 49.2827C35.9238 39.6161 43.0648 36.5952 44.9048 29C44.9048 28.9568 44.9048 28.9568 44.9048 28.9137C45.6057 26.8423 46 24.6414 46 22.311C46 10.0119 35.4419 -4.61509e-07 22.9124 -1.00919e-06C10.4267 -1.55496e-06 3.36197e-06 10.3571 2.82436e-06 22.6562C2.74136e-06 24.5551 0.262858 26.4107 0.744761 28.1369C0.744761 28.1801 0.744761 28.2232 0.744761 28.3095C1.22667 30.2083 2.01524 31.8482 2.97905 33.3586C3.63619 34.4375 4.33715 35.4301 5.16953 36.3795C7.75429 39.7024 10.6457 43.0685 11.8286 48.6354C11.8724 48.8943 11.96 49.067 12.0038 49.2396C13.0552 54.2455 17.5238 58 22.9124 58Z" fill="currentColor"/>
                        </svg>
                        <h5 className='caps pt1 founders mb0 pb0 medium show--800'>{subTitle}</h5>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col pr z1 c10--1000 bc bl--1000'>
                <div className=' p2 pb3--800 pt3--800'>
                  {spices && spices.map((spice, i) => {
                    return (
                      <div key={i} className={cx('spice__info', {
                        'hidden': active !== i
                      })}>
                        <div className='f row jcb aist fw'>
                          <div className='x col c14  c6--800'>
                            <div className='df y jcb fdc'>
                              <div>
                                <h2 className='mt0 pt0 serif italic'>{spice.productTitle ? spice.productTitle : spice.title}</h2>
                                {spice.stats && spice.stats.map(stat => (
                                  <div className='db hide--800' key={stat._key}>
                                    <span className='courier s14 '>{stat.identifier}: {stat.stat}</span>
                                  </div>
                                ))}
                                <div className='mt2'>
                                  {spice.description && (<BlockContent blocks={spice.description} serializers={Serializer} />)}
                                  {spice.fact && (<BlockContent blocks={spice.fact} serializers={Serializer} />)}
                                </div>
                              </div>
                              <div>
                              {spice.product && (
                                <Link to={`/${spice.product._type === 'collection' ? 'collections' : 'products'}/${spice.product.slug.current}`} className='link-underline founders caps' target='_blank' rel='noopener'>
                                  {spice.linkCtaOverride ? spice.linkCtaOverride : spice.product.title} <LinkArrow />
                                </Link>
                              )}
                              </div>
                            </div>
                          </div>
                          <div className='x col c14 c6--800'>
                            {spice.image ? (
                              <div className='mt3 mt0--800 spice__image-block'>
                                <Image className='mb1' imageId={spice.image} alt={spice.imageAlt} />
                              </div>
                            ) : (
                              <span className='empty  db'>Missing Image</span>
                            )}
                            {spice.stats && spice.stats.map(stat => (
                              <div className='db show--800' key={stat._key}>
                                <span className='courier s14 '>{stat.identifier}: {stat.stat}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
