import React from "react"
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'


export interface WhatToExpectProps {
  data: {
    whatToExpect: {
      text: []
      image: {},
      experienceIcons: []
    }
  }
}

export const WhatToExpect = ({ data }: WhatToExpectProps) => {
  const { whatToExpect: {
    text,
    image,
    experienceIcons
  } } = data
  return (
    <div className='p2 mt4 mb2 mt8--800 mb4--800'>
      <div className="container--xl p2--800 outer mxa  x">
        <div className='ba bc'>
          <div className='f jcb fw aist fdrr x'>
            <div className='grid--50-800 bc bl1--800'>
              <div className='p3--600 y df fdc jcb y aic pb5--800 pt5--800 tc--800 x'>
                <div className={cx('container--500 p2 tl tc--800 mxa rich__text')}>
                  <BlockContent blocks={text} serializers={Serializer} />
                </div>
                <div className='row gutter--none x'>
                  {experienceIcons.map((icon) => (
                    <div key={icon._key} className='col c14 c4a--800 tl'>
                      <div className='row df fw jcs aic gutter--none'>
                        <div className='col c4 c14--800'>

                          <Image className='p1' imageId={icon.image.imageId} alt={icon.subTitle} />
                        </div>
                        <div className='col c7 c14--800 tc--800'>
                          <span className='s16 founders'>{icon.subTitle}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='grid--50-800'>
              <div className='p3--600 image__badge pr p4--1000 x'>
                <Image imageId={image.imageId} src={image.imageUrl} alt={image.alt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatToExpect