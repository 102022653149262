import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import { PageLink } from 'src/components/link'
import { Image } from 'src/components/image'
import { ProductInt } from 'src/interfaces/product'

export const RecipeCard = ({
  _id,
  content: {
    main,
    shopify
  },
  chef,
  className,
  title
}: ProductInt ) => {
  return (
    <div className={cx('col c6 c3a--800', className)} key={_id}>
      <PageLink className='block' to={`/products/${main.slug.current}`}>
        {main.mainImage && (<Image className='block  mb1 x' imageId={main.mainImage.asset._ref} alt={main.title} />)}
        <div className=''>
          <span className='s24 founders'>{title}</span>
          {chef && (<span className='db caps mt1 ss12 founders medium'>by chef {chef}</span>)}
        </div>
      </PageLink>
    </div>
  )
}
